import React, { useState, useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next'; // Importa l'hook di traduzione
import { languages } from '../locales/languages';
import '../i18n';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { ProductsContext } from '../context/ProductsContext';
import { SelectionsContext } from '../context/SelectionsContext';
import { PhoneArrowDownLeftIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
//Importo Autenticazione Token
import getAuthToken from '../features/auth/axiosSetup';

//Importo Toast di notifiche
import Toast from '../componets/toast';
import ToastSuccess from '../componets/toast_success';


export default function RisultatiPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { products, setProducts } = useContext(ProductsContext);
  const { selections, setSelections } = useContext(SelectionsContext);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefono: '',
    stato: '',
    messaggio: '',
  });
  const [show, setShow] = useState(false)
  const [titolo, setTitolo] = useState('')
  const [messaggio, setMessaggio] = useState('')

  const [show1, setShow1] = useState(false)
  const [titolo1, setTitolo1] = useState('')
  const [messaggio1, setMessaggio1] = useState('')

  const [selectedLanguage, setSelectedLanguage] = useState(languages.find(lang => lang.code === i18n.language));

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang.code);
    setSelectedLanguage(lang);
    setIsDropdownOpen(false);
  };

  const handleRedirectPezzolato = () => {
    window.location.href = `${process.env.REACT_APP_SITO_PEZZOLATO}`;
  };



  const handleBackToHome = () => {
    navigate('/');
  };

  const handleBackToAzionamento = () => {
    navigate('/azionamento');
  };

  // Funzione per mappare i valori di selections alle chiavi di traduzione
  const mapSelectionsToTranslations = (key, values) => {
  if (!values || values.length === 0) return '';

  return values
    .map((value) => {
      switch (key) {
        case 'azionamento':
          switch (value) {
            case 'presa_forza':
              return `${t(`${key}.presa_forza`)} ${t(`${key}.presa_di_forza_hp`)}`;
            case 'autonomo':
              return `${t(`${key}.motore_autonomo`)} ${t(`${key}.motore_autonomo_hp`)}`;
            case 'camion':
              return `${t(`${key}.camion`)} ${t(`${key}.camion_hp`)}`;
            case 'hackertruck':
              return t(`${key}.hackertruck`);
            case 'elettrico':
              return t(`${key}.motore_elettrico`);
            default:
              return t(`${key}.${value}`);
          }
        case 'produttivita':
            switch (value) {
              case 'p10_20':
                return `${t(`${key}.p10_20`)}`;
              case 'p15_50':
                return `${t(`${key}.p15_50`)}`;
              case 'p80_120':
                return `${t(`${key}.p80_120`)}`;
              case 'pmax_100':
                return t(`${key}.pmax_100`);
              case 'pmax_200':
                return t(`${key}.pmax_200`);
              default:
                return t(`${key}.${value}`);
            }
        case 'alimentazione':
          switch (value) {
            case 'manuale':
              return t(`${key}.titolo_manuale`);
            case 'esterno':
              return t(`${key}.titolo_esterno`);
            case 'integrato':
              return t(`${key}.titolo_integrato`);
            case 'automatico':
              return t(`${key}.titolo_automatico`);
            default:
              return t(`${key}.${value}`);
          }
        case 'tipologia':
          switch (value) {
            case 'tronchi':
              return t(`${key}.tronchi`);
            case 'intera':
              return t(`${key}.intera`);
            case 'potature':
              return t(`${key}.potature`);
            case 'rifili':
              return t(`${key}.rifili`);
            case 'sciaveri':
              return t(`${key}.sciaveri`);
            case 'scarti':
              return t(`${key}.scarti`);
            default:
              return t(`${key}.${value}`);
          }
        case 'produzione':
          switch (value) {
            case 'grandi_caldaie':
              return t(`${key}.grandi_caldaie`);
            case 'caldaie_domestiche':
              return t(`${key}.caldaie_domestiche`);
            case 'prod_pellet':
              return t(`${key}.prod_pellet`);
            case 'riduzione_volume':
              return t(`${key}.riduzione_volume`);
            default:
              return t(`${key}.${value}`);
          }
        default:
          return t(`${key}.${value}`);
      }
    })
    .join(', ');
   };

  //Funzione per Effettuare lo scroll fino all'Form 
  const scrollToForm = () => {
    const element = document.getElementById('form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //Funzione per Gestire il Form di Invio contatto via Email 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


//Funzione Invio email da form con tutte le scelte i dati form, gli articoli e la lingua 

const handleSubmit = async (e) => {
    e.preventDefault(); // Previeni il comportamento di default del form
  
    // Controlla se tutti i campi obbligatori del form (escluso il messaggio) sono pieni
    if (!formData.nome || !formData.email || !formData.telefono || !formData.stato) {
      console.log('Errore: alcuni campi obbligatori non sono stati compilati.');
  
      // Mostra errore con notifica
      setTitolo(t('risultati.messaggio_titolo'));
      setMessaggio(t('risultati.messaggio_vuoto'));
      setShow(true);
  
      // Chiudi il Toast dopo 2 secondi
      setTimeout(() => {
        setShow(false);
        setTitolo('');
        setMessaggio('');
      }, 2000);
  
      return; // Esci dalla funzione se non tutti i campi obbligatori sono compilati
    }
  
    try {
      // Ottieni il token in modo asincrono
      const token = await getAuthToken();
  
      // Estrai solo i `cod_article` dai prodotti
      const codArticles = products.map(product => product.cod_article);

      // Estrai le selezioni tradotte in lingua italiana, forzando la lingua italiana
      const currentLanguage = i18n.language; // Salva la lingua corrente
      i18n.changeLanguage('it'); // Cambia temporaneamente la lingua in italiano
      const translatedSelections = Object.keys(selections).reduce((acc, key) => {
        if (key === 'tipo_produzione') {
          acc[key] = selections[key].map(value => t(`produzione.${value}`)); // Utilizza la chiave 'produzione' per 'tipo_produzione'
        } else {
          acc[key] = mapSelectionsToTranslations(key, selections[key]);
        }
        
        return acc;
      }, {});
      i18n.changeLanguage(currentLanguage); // Ripristina la lingua originale

      // Converti il codice della lingua in un nome leggibile
      const languageMap = {
        it: 'Italiano',
        fr: 'Francese',
        es: 'Spagnolo',
        en: 'Inglese',
        de: 'Tedesco'
      };
      const userLanguage = languageMap[currentLanguage] || 'Inglese';
  
      // Se tutti i campi sono riempiti, procedi con la chiamata Axios
      const response = await axios.post(
        `${process.env.REACT_APP_API}/products/send-form`,
        {
          formData,
          codArticles, // Invia solo i `cod_article` dei prodotti
          selections: translatedSelections, // Invia le selezioni tradotte in italiano
          userLanguage, // Invia la lingua corrente dell'utente in formato leggibile
        },
        {
          headers: {
            'Authorization': `Bearer ${token}` // Usa il token ottenuto
          }
        }
      );
  
      // Controlla la risposta del server
      if (response.data.status === true) {
        // Mostra notifica di successo
        setTitolo1(t('risultati.messaggio_titolo'));
        setMessaggio1(t('risultati.messaggio_successo'));
        setShow1(true);
  
        // Chiudi il Toast dopo 2 secondi
        setTimeout(() => {
          setShow1(false);
          setTitolo1('');
          setMessaggio1('');
        }, 6000);

        //Svuoto i campi del Form
        setFormData({
          nome: '',
          email: '',
          telefono: '',
          stato: '',
          messaggio: '',
      });

      } else {

            // Mostra errore con notifica
            setTitolo(t('risultati.messaggio_titolo'));
            setMessaggio(t('risultati.messaggio_errore'));
            setShow(true);
        
            // Chiudi il Toast dopo 2 secondi
            setTimeout(() => {
              setShow(false);
              setTitolo('');
              setMessaggio('');
            }, 4000);

      }
  
    } catch (error) {
      console.error('Errore durante l\'invio dei dati:', error);
  
      // Mostra errore con notifica
      setTitolo(t('risultati.messaggio_titolo'));
      setMessaggio(t('risultati.messaggio_errore'));
      setShow(true);
  
      // Chiudi il Toast dopo 2 secondi
      setTimeout(() => {
        setShow(false);
        setTitolo('');
        setMessaggio('');
      }, 4000);
    }
};

  return (
    <>
      <Helmet>
        <title>© {t('risultati.head_title')} | Pezzolato Spa</title>
        <meta name="description" content={t('risultati.meta_descr')} />
      </Helmet>

      <header className="relative bg-[#F2F2F2] py-10 pb-20 px-6 lg:px-32">
        <div className="flex justify-between items-start">
        <button onClick={handleBackToHome}>
          <img
            src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
            alt="Pezzolato Logo"
            className="w-32 lg:w-60"
          />
          </button>
          <div className="relative">
            <button onClick={toggleDropdown} className="flex items-center space-x-2">
              {selectedLanguage.flag}
              <span>{selectedLanguage.name}</span>
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded shadow-lg z-20">
                <ul className="text-black text-sm">
                  {languages.map((lang) => (
                    <li
                      key={lang.code}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                      onClick={() => handleLanguageChange(lang)}
                    >
                      {lang.flag}
                      <span>{lang.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="mt-8">
          <h2 className="w-full lg:w-1/2 text-xl lg:text-2xl font-semibold text-color-s-green">
          <Trans i18nKey="risultati.hero"></Trans>
          </h2>
        </div>

        <h1 className="w-full lg:w-4/12 mt-8 text-3xl lg:text-5xl font-bold text-color-s-green">
        {t('risultati.sub_hero')}
        </h1>

        <div className="flex flex-col lg:flex-row items-start mt-8">
          {/* Selections Section */}
          <div className="w-full lg:w-10/12 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <button className="bg-white border border-[#707070] rounded-2xl px-4 py-4 text-sm font-medium text-[#707070] shadow">
            {t('azionamento.head_title')}: {mapSelectionsToTranslations('azionamento', selections.azionamento)}
            </button>
            <button className="bg-white border border-[#707070] rounded-2xl px-4 py-4 text-sm font-medium text-[#707070] shadow">
            {t('produttivita.head_title')}: {mapSelectionsToTranslations('produttivita', selections.produttivita)}
            </button>
            <button className="bg-white border border-[#707070] rounded-2xl px-4 py-4 text-sm font-medium text-[#707070] shadow">
            {t('alimentazione.head_title')}: {mapSelectionsToTranslations('alimentazione', selections.alimentazione)}
            </button>
            <button className="bg-white border border-[#707070] rounded-2xl px-4 py-4 text-sm font-medium text-[#707070] shadow">
            {t('tipologia.title_res')}: {mapSelectionsToTranslations('tipologia', selections.tipologia)}
            </button>
            <button className="bg-white border border-[#707070] rounded-2xl px-4 py-4 text-sm font-medium text-[#707070] shadow">
            {t('produzione.title_res')}: {mapSelectionsToTranslations('produzione', selections.tipo_produzione)}
            </button>
            <p className="mt-2 py-4 text-sm text-center text-gray-600">
            {t('risultati.edit')} <span className="underline cursor-pointer"><button className="underline cursor-pointer" onClick={() => handleBackToAzionamento()}>{t('risultati.edit_1')}</button></span>
            </p>
          </div>

          {/* Call to Action Section */}
          <button onClick={() => scrollToForm()}>
          <div className="mt-10 lg:mt-0 lg:absolute lg:right-5 lg:w-2/12 flex ml-6">
            {/* Icon Circle */}
            <div className="flex flex-col items-center ">
              <div className="bg-[#556423] text-white rounded-full w-24 h-24 flex items-center justify-center">
                <PhoneArrowDownLeftIcon className="h-12 w-12 text-white" />
              </div>
              {/* Centered Text */}
              <p className="w-6/12 lg:w-4/12 mt-2 text-center text-[#556423] text-sm font-semibold">
              {t('risultati.cta')}
              </p>
            </div>
          </div>
          </button>
        </div>
      </header>

      {/* New Section: Le nostre proposte */}
      <section className="px-5 lg:px-32 py-10 lg:py-16 bg-white">
        <h2 className="text-4xl font-semibold text-color-s-green mb-5 lg:mb-10 text-center lg:text-start">{t('risultati.proposte')}</h2>

        {/* Inzio Ciclo */}
        

        {products.map((product, index) => (
          <div key={index} className="flex flex-col lg:flex-row items-stretch gap-10 bg-[#F2F2F2] rounded-3xl shadow-xl my-10 lg:my-20">
            <div className="w-full lg:w-6/12 h-80 lg:h-auto overflow-hidden">
              <img
                src={`${process.env.REACT_APP_FILESERVER}/prodotti/${product.img_url}`}
                alt={product.cod_article}
                className="object-cover w-full h-full rounded-t-3xl lg:rounded-l-3xl lg:rounded-t-none lg:rounded-tl-3xl"
              />
            </div>
            <div className="w-full lg:w-6/12 p-3 lg:p-12 flex flex-col justify-between">
              <div>
                <h3 className="text-4xl poppins-semibold text-color-s-res text-center">{product.cod_article}</h3>
                <p className="text-xl mt-4 poppins-semibold text-color-s-res text-center\" dangerouslySetInnerHTML={{ __html: product.title_description[i18n.language] }} style={{ textAlign: 'center' }}></p>
                <p className="text-lg mt-4 poppins-regular text-color-s-res text-center">
                  {product.description[i18n.language]}
                </p>
              </div>
              <div className="flex justify-center mt-6 gap-2 lg:gap-8">
                {[1, 2, 3].map((iconIndex) => (
                  <div
                    key={iconIndex}
                    className="flex flex-col items-center justify-center bg-white border border-[#556423] rounded-3xl p-4 lg:p-4 shadow-sm h-full flex-grow min-h-[180px] w-full"
                  >
                    <img
                      src={`${process.env.REACT_APP_FILESERVER}/icone/${product.icon[`icon_${iconIndex}_${i18n.language}`][0]?.url}`}
                      alt={product.icon[`icon_${iconIndex}_${i18n.language}`][0]?.icon_title}
                      className="h-12 lg:h-20 object-cover"
                    />
                    <p className="text-xs lg:text-base font-semibold mt-2 text-center text-color-s-res ">
                      {product.icon[`icon_${iconIndex}_${i18n.language}`][0]?.icon_title}
                    </p>
                    <p className="text-xs text-gray-500 text-center ">
                      {product.icon[`icon_${iconIndex}_${i18n.language}`][0]?.icon_subtitle}
                    </p>
                  </div>
                ))}
              </div>

              <button
                onClick={() => window.location.href = product.links[i18n.language]}
                className="mt-10 lg:mt-8 bg-[#556423] text-white poppins-bold py-4 px-10 rounded-full inline-flex justify-center items-center text-xl lg:text-3xl uppercase tracking-wider transition duration-300 ease-in-out hover:bg-[#6a7a29] mx-auto lg:mx-0 w-full lg:self-start"
              >
                {t('risultati.button')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="ml-3 w-5 h-5 lg:w-8 lg:h-8"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}

        {/* Fine Ciclo*/}

        
      </section>

      {/* New Section: Form */}
      <section id="form" className='w-full gap-6 pb-10 bg-white px-4 lg:px-96'>
        <div className="relative bg-[#556423] text-white py-16 px-6 lg:px-32 flex justify-center" style={{ borderRadius: '4rem' }}>
          <div className="w-full lg:w-10/12">
            <h2 className="text-2xl lg:text-3xl text-center font-semibold mb-6">
            {t('risultati.header_form')}
            </h2>
            <form className="flex flex-col items-center gap-6" onSubmit={handleSubmit}>
            <input
              type="text"
              name="nome"
              placeholder={t('risultati.pl_nome')}
              value={formData.nome}
              onChange={handleChange}
              className="w-full bg-white text-black py-3 px-4 rounded-full outline-none"
            />
            <input
              type="email"
              name="email"
              placeholder={t('risultati.pl_email')}
              value={formData.email}
              onChange={handleChange}
              className="w-full bg-white text-black py-3 px-4 rounded-full outline-none"
            />
            <input
              type="tel"
              name="telefono"
              placeholder={t('risultati.pl_telefono')}
              value={formData.telefono}
              onChange={handleChange}
              className="w-full bg-white text-black py-3 px-4 rounded-full outline-none"
            />
            <input
              type="text"
              name="stato"
              placeholder={t('risultati.pl_stato')}
              value={formData.stato}
              onChange={handleChange}
              className="w-full bg-white text-black py-3 px-4 rounded-full outline-none"
            />
            <textarea
              name="messaggio"
              placeholder={t('risultati.pl_messaggio')}
              value={formData.messaggio}
              onChange={handleChange}
              className="w-full bg-white text-black py-3 px-4 rounded-2xl outline-none h-32"
            ></textarea>
            <div className="flex items-center space-x-2 mt-4">
              <input type="checkbox" id="privacy-policy" className="w-4 h-4" />
              <label htmlFor="privacy-policy" className="text-sm">
                {t('risultati.privacy')}
              </label>
            </div>
            <button
              type="submit"
              className="mt-10 lg:mt-8 bg-white text-[#556423] poppins-semibold py-4 px-10 rounded-full inline-flex justify-center items-center text-xl lg:text-3xl uppercase tracking-wider transition duration-300 ease-in-out hover:bg-grey-200 mx-auto lg:mx-0 w-full lg:self-start"
            >
              {t('risultati.invia')}
            </button>
          </form>
          </div>
        </div>
      </section>

      {/* Solutions Section (under the two columns) */}
      <section className="text-center lg:text-center pb-12 pt-64 home-section -mt-64">
        <h2 className="text-3xl mb:text-4xl lg:text-5xl poppins-light text-color-s-green text-center mt-2 lg:mt-10">
          {t('home.solutions_title')}
        </h2>
        <p className="mt-4 text-lg text-color-s-green mx-5 lg:mx-20 poppins-extralight lg:text-3xl lg:leading-[1.29] mt-6 lg:mt-14">
          <Trans i18nKey="home.solutions_description"></Trans>
        </p>
        <p className="mt-4 text-lg text-color-s-green mx-5 lg:mx-20 poppins-extralight lg:text-3xl lg:leading-[1.29] mt-6 lg:mt-14">
          <Trans i18nKey="home.solutions_description_2"></Trans>
        </p>
        <p className="mt-4 text-lg text-color-s-green mx-5 lg:mx-20 poppins-extralight lg:text-3xl lg:leading-[1.29] mt-6 lg:my-10 underline">
          {t('home.solutions_cta')}
        </p>
      </section>

      {/* Footer */}
      <footer className="text-center py-6 bk-color-green text-white poppins-regular">
        <button onClick={() => handleRedirectPezzolato()}>
          <p className="inline-flex items-center">
          {t('risultati.footer')}
            <span className="font-bold ml-2">
              <img
                src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                alt="Pezzolato Logo"
                className="w-32"
              />
            </span>
          </p>
        </button>
      </footer>

        {/* Importo Notifiche */}
     <Toast show={show} setShow={setShow} titolo={titolo} messaggio={messaggio}/>
     <ToastSuccess show={show1} setShow={setShow1} titolo={titolo1} messaggio={messaggio1}/>
    </>
  );
}
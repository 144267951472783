import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';
import translationFR from './locales/fr/translation.json';
import translationDE from './locales/de/translation.json';
import translationES from './locales/es/translation.json';

// Definisci le risorse per ogni lingua
const resources = {
  en: {
    translation: translationEN
  },
  it: {
    translation: translationIT
  },
  fr: {
    translation: translationFR
  },
  de: {
    translation: translationDE
  },
  es: {
    translation: translationES
  }
};

// Inizializza i18next
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it', // Lingua predefinita
    fallbackLng: 'en', // Lingua di fallback se una traduzione non è disponibile
    interpolation: {
      escapeValue: false // React gestisce già la protezione da XSS
    }
  });

export default i18n;

import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from '../locales/languages';
import '../i18n';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ProductsContext } from '../context/ProductsContext';
import { SelectionsContext } from '../context/SelectionsContext';
import Toast from '../componets/toast';
import { Tooltip } from 'react-tooltip';

export default function AlimentazionePage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { products, setProducts } = useContext(ProductsContext); 
  const { selections, setSelections } = useContext(SelectionsContext);
  const { alimentazione } = selections; 
  const [selectedOptions, setSelectedOptions] = useState(alimentazione || []);
  const [show, setShow] = useState(false);
  const [titolo, setTitolo] = useState('');
  const [messaggio, setMessaggio] = useState('');
  const [availableOptions, setAvailableOptions] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(languages.find(lang => lang.code === i18n.language));

  useEffect(() => {
    const calculateAvailableOptions = () => {
      const options = {
        manuale: false,
        esterno: false,
        integrato: false,
        automatico: false,
      };

      products.forEach(product => {
        Object.keys(options).forEach(option => {
          if (product.tags[option]) {
            options[option] = true;
          }
        });
      });

      setAvailableOptions(options);
    };

    calculateAvailableOptions();
  }, [products]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleBackPage = () => {
    navigate('/azionamento');
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang.code);
    setSelectedLanguage(lang);
    setIsDropdownOpen(false);
  };

  const handleOptionSelect = (option) => {
    if (!availableOptions[option]) return;

    let updatedOptions;
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((o) => o !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedOptions);
    setSelections({ ...selections, alimentazione: updatedOptions });
  };

  const handleSubmit = () => {
    if (selectedOptions.length === 0) {
      setTitolo(t('alimentazione.titolo_toast'));
      setMessaggio(t('alimentazione.messaggio_toast'));
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 2000);
      return;
    }

    try {
      let matchingProductsSet = new Set();
      selectedOptions.forEach(option => {
        products.forEach(product => {
          if (product.tags[option]) {
            matchingProductsSet.add(product);
          }
        });
      });

      const uniqueFilteredProducts = Array.from(matchingProductsSet);
      setProducts(uniqueFilteredProducts);

      navigate('/tipologia');

    } catch (error) {
      console.error('Error filtering products:', error);
    }
  };

  const handleRedirectPezzolato = () => {
    window.location.href = `${process.env.REACT_APP_SITO_PEZZOLATO}`;
  };
  
  return (
    <>
      <Helmet>
        <title>© {t('alimentazione.head_title')} | Pezzolato Spa</title>
        <meta name="description" content={t('alimentazione.meta_descr')} />
      </Helmet>

          <div className="min-h-screen flex flex-col justify-between bg-white">
          {/* Header */}
        <header className="bg-[#F2F2F2]">
          <div className="flex justify-between items-center w-full py-6 px-4 lg:px-32">
            <button onClick={handleBackToHome}>
              <img
                src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                alt="Pezzolato Logo"
                className="w-44"
              />
            </button>
            {/* Language Selector */}
            <div className="relative">
              <button onClick={toggleDropdown} className="flex items-center space-x-2">
                {selectedLanguage.flag}
                <span>{selectedLanguage.name}</span>
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded shadow-lg z-20">
                  <ul className="text-black text-sm">
                    {languages.map((lang) => (
                      <li
                        key={lang.code}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                        onClick={() => handleLanguageChange(lang)}
                      >
                        {lang.flag}
                        <span>{lang.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          
          {/* Progress bar */}
          <div className="flex justify-between w-full px-0 lg:px-0">
            <div onClick={() => handleBackPage()}  className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2 cursor-pointer">
              <span className="poppins-semibold text-color-s-grey">1</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">2</span>
            </div>
            <div className="h-2 w-1/5 bk-color-green text-center pt-2">
              <span className="poppins-semibold text-color-s-green">3</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">4</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">5</span>
            </div>
          </div>
        </header>

            <div className="flex flex-col justify-center items-center text-center my-10 w-full">
            <h1 className="text-4xl lg:text-5xl font-semibold text-color-s-green mx-2 mb-0 lg:mb-2 lg:mt-0">
              {t('alimentazione.tipo_alimentazione')}
            </h1>
            <p className="text-lg text-gray-600 mt-0 lg:mt-0 mb-8 lg:mb-10">{t('alimentazione.opzioni')}</p>

  
            {/* Create the 2x2 grid */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full max-w-7xl px-5 lg:px-0">
              
              {/* Button 1 */}
              <div className="relative">
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-html={!availableOptions.manuale ? t('alimentazione.tooltip') : ""}
                  onClick={() => handleOptionSelect('manuale')}
                  className={`w-full h-48 px-6 py-3 flex flex-col justify-center items-center text-center border rounded-xl ${
                    selectedOptions.includes('manuale') && availableOptions.manuale
                      ? 'bg-[#556423] text-white'
                      : availableOptions.manuale
                      ? 'bg-white'
                      : 'bg-gray-300 cursor-not-allowed'
                  }`}
                  disabled={!availableOptions.manuale}
                >
                  <span className="text-lg lg:text-2xl font-semibold">{t('alimentazione.titolo_manuale')}</span>
                  <span className="text-sm lg:text-base font-light">{t('alimentazione.sub_titolo_manuale')}</span>
                </button>
              </div>
              
              {/* Button 2 */}
              <div className="relative">
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-html={!availableOptions.esterno ? t('alimentazione.tooltip') : ""}
                  onClick={() => handleOptionSelect('esterno')}
                  className={`w-full h-48 px-6 py-3 flex flex-col justify-center items-center text-center border rounded-xl ${
                    selectedOptions.includes('esterno') && availableOptions.esterno
                      ? 'bg-[#556423] text-white'
                      : availableOptions.esterno
                      ? 'bg-white'
                      : 'bg-gray-300 cursor-not-allowed'
                  }`}
                  disabled={!availableOptions.esterno}
                >
                  <span className="text-lg lg:text-2xl font-semibold">{t('alimentazione.titolo_esterno')}</span>
                  <span className="text-sm lg:text-base font-light">{t('alimentazione.sub_titolo_esterno')}</span>
                </button>
              </div>
              
              {/* Button 3 */}
              <div className="relative">
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-html={!availableOptions.integrato ? t('alimentazione.tooltip') : ""}
                  onClick={() => handleOptionSelect('integrato')}
                  className={`w-full h-48 px-6 py-3 flex flex-col justify-center items-center text-center border rounded-xl ${
                    selectedOptions.includes('integrato') && availableOptions.integrato
                      ? 'bg-[#556423] text-white'
                      : availableOptions.integrato
                      ? 'bg-white'
                      : 'bg-gray-300 cursor-not-allowed'
                  }`}
                  disabled={!availableOptions.integrato}
                >
                  <span className="text-lg lg:text-2xl font-semibold">{t('alimentazione.titolo_integrato')}</span>
                  <span className="text-sm lg:text-base font-light">{t('alimentazione.sub_titolo_integrato')}</span>
                </button>
              </div>
              
              {/* Button 4 */}
              <div className="relative">
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-html={!availableOptions.automatico ? t('alimentazione.tooltip') : ""}
                  onClick={() => handleOptionSelect('automatico')}
                  className={`w-full h-48 px-6 py-3 flex flex-col justify-center items-center text-center border rounded-xl ${
                    selectedOptions.includes('automatico') && availableOptions.automatico
                      ? 'bg-[#556423] text-white'
                      : availableOptions.automatico
                      ? 'bg-white'
                      : 'bg-gray-300 cursor-not-allowed'
                  }`}
                  disabled={!availableOptions.automatico}
                >
                  <span className="text-lg lg:text-2xl font-semibold">{t('alimentazione.titolo_automatico')}</span>
                  <span className="text-sm lg:text-base font-light">{t('alimentazione.sub_titolo_automatico')}</span>
                </button>
              </div>
            </div>
  
            <Tooltip id="tooltip" />
  
            {/* Submit Button */}
            <button
              onClick={handleSubmit}
              style={{ borderWidth: '3px' }}
              className="border-[#556423] text-[#556423] text-xl lg:text-3xl uppercase font-bold py-4 w-60 lg:w-3/4 rounded-full mt-8 lg:mt-20 flex items-center justify-center"
            >
              {t('alimentazione.cta')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="ml-2 w-5 h-5 lg:w-8 lg:h-8"
              >
                <path
                  fillRule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>


            <footer className="text-center py-6 bg-[#556423] text-white">
              <button onClick={() => handleRedirectPezzolato()}>
                <p className="inline-flex items-center">
                  {t('alimentazione.footer')}
                  <span className="font-bold ml-2">
                    <img
                      src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                      alt="Pezzolato Logo"
                      className="w-32"
                    />
                  </span>
                </p>
              </button>
            </footer>
          </div>

      <Toast show={show} setShow={setShow} titolo={titolo} messaggio={messaggio} />
    </>
  );
}

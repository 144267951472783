import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from '../locales/languages';
import '../i18n';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

import getAuthToken from '../features/auth/axiosSetup';
import { ProductsContext } from '../context/ProductsContext';
import { SelectionsContext } from '../context/SelectionsContext';
import Toast from '../componets/toast';
import { Tooltip } from 'react-tooltip';


export default function ProduzionePage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { products, setProducts } = useContext(ProductsContext); // Prendi i prodotti dal contesto
  const { selections, setSelections } = useContext(SelectionsContext);
  const { tipo_produzione } = selections;
  const [selectedOptions, setSelectedOptions] = useState(tipo_produzione || []);
  const [show, setShow] = useState(false);
  const [titolo, setTitolo] = useState('');
  const [messaggio, setMessaggio] = useState('');
  const [availableOptions, setAvailableOptions] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(languages.find(lang => lang.code === i18n.language));
    
  useEffect(() => {
    // Elabora i prodotti per ottenere le opzioni disponibili
    const calculateAvailableOptions = () => {
      const options = {
        grandi_caldaie: false,
        caldaie_domestiche: false,
        prod_pellet: false,
        riduzione_volume: false,
      };
     
      products.forEach(product => {
        Object.keys(options).forEach(option => {
          if (product.tags[option]) {
            options[option] = true; // Imposta l'opzione come disponibile se il tag è true
          }
        });
      });

      setAvailableOptions(options); // Aggiorna le opzioni disponibili
    };

    calculateAvailableOptions();
  }, [products]); // Ricalcola ogni volta che i prodotti cambiano
  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleBackPage = () => {
    navigate('/azionamento');
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang.code);
    setSelectedLanguage(lang);
    setIsDropdownOpen(false);
  };

  const handleOptionSelect = (option) => {
    if (!availableOptions[option]) return; // Se l'opzione non è disponibile, non fare nulla
    let updatedOptions;
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((o) => o !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    setSelections({ ...selections, tipo_produzione: updatedOptions });
  };

  const handleSubmit = () => {
    if (selectedOptions.length === 0) {
      setTitolo(t('produzione.titolo_toast'));
      setMessaggio(t('produzione.messaggio_toast'));
      setShow(true);
  
      setTimeout(() => {
        setShow(false);
      }, 2000);
      return;
    }
  
    try {
      // Use a Set to store unique products across all selected options
      let matchingProductsSet = new Set();
  
      // Loop over each selected option
      selectedOptions.forEach(option => {
        // Find products that match the current option
        products.forEach(product => {
          if (product.tags[option]) {
            // Add the matching product to the Set (Set ensures uniqueness)
            matchingProductsSet.add(product);
          }
        });
      });
  
      // Convert the Set back to an array and update the Products context
      const uniqueFilteredProducts = Array.from(matchingProductsSet);
      setProducts(uniqueFilteredProducts);
  
      // Navigate to the products page after filtering if necessary
      navigate('/risultati');
    } catch (error) {
      console.error('Error filtering products:', error);
    }
  };

  const handleRedirectPezzolato = () => {
    window.location.href = `${process.env.REACT_APP_SITO_PEZZOLATO}`;
  };
  
  





  return (
    <>
      <Helmet>
        <title>© {t('produzione.head_title')} | Pezzolato Spa</title>
        <meta name="description" content={t('produzione.meta_descr')} />
      </Helmet>

          <div className="min-h-screen flex flex-col justify-between bg-white">
              {/* Header */}
              <header className="bg-[#F2F2F2]">
          <div className="flex justify-between items-center w-full py-6 px-4 lg:px-32">
            <button onClick={handleBackToHome}>
              <img
                src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                alt="Pezzolato Logo"
                className="w-44"
              />
            </button>
            {/* Language Selector */}
            <div className="relative">
              <button onClick={toggleDropdown} className="flex items-center space-x-2">
                {selectedLanguage.flag}
                <span>{selectedLanguage.name}</span>
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded shadow-lg z-20">
                  <ul className="text-black text-sm">
                    {languages.map((lang) => (
                      <li
                        key={lang.code}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                        onClick={() => handleLanguageChange(lang)}
                      >
                        {lang.flag}
                        <span>{lang.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          
          {/* Progress bar */}
          <div className="flex justify-between w-full px-0 lg:px-0">
            <div onClick={() => handleBackPage()}  className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2 cursor-pointer">
              <span className="poppins-semibold text-color-s-grey">1</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">2</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">3</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">4</span>
            </div>
            <div className="h-2 w-1/5 bk-color-green text-center pt-2">
              <span className="poppins-semibold text-color-s-green">5</span>
            </div>
          </div>
        </header>


            <main className="flex flex-col justify-center items-center text-center my-14 w-full">
              <h1 className="text-4xl lg:text-5xl font-semibold text-color-s-green mx-2 mb-0 lg:mb-1 lg:mt-0">
                {t('produzione.tipo_produzione')}
              </h1>
              <p className="text-lg text-gray-600 mt-0 lg:mt-0 mb-0 lg:mb-14">{t('produzione.opzioni')}</p>


              <div className="flex flex-col lg:flex-row justify-center items-center text-center my-14 lg:my-0 w-full">
                <div className="flex flex-col lg:flex-row justify-between w-full max-w-5xl">
                  <div className="flex justify-center items-center w-full lg:w-1/2 mb-14 lg:mb-0">
                    <img
                      src={`${process.env.REACT_APP_FILESERVER}/produzione.png`}
                      alt="Segnaposto"
                      className="w-7/12 object-contain"
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center lg:items-start w-full lg:w-1/2 space-y-4 px-5 lg:px-0">
                  <div className="relative">
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-html={!availableOptions.grandi_caldaie ? t('produzione.tooltip') : ""}
                      onClick={() => handleOptionSelect('grandi_caldaie')}
                      className={`w-80 lg:w-96 px-6 py-3 text-center border rounded-full ${
                        selectedOptions.includes('grandi_caldaie') && availableOptions.grandi_caldaie
                          ? 'bg-[#556423] text-white'
                          : availableOptions.grandi_caldaie
                          ? 'bg-white'
                          : 'bg-gray-300 cursor-not-allowed'
                      }`}
                      disabled={!availableOptions.grandi_caldaie}
                    >
                       {t('produzione.grandi_caldaie')}
                    </button>
                  </div>

                  <div className="relative">
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-html={!availableOptions.caldaie_domestiche ? t('produzione.tooltip') : ""}
                      onClick={() => handleOptionSelect('caldaie_domestiche')}
                      className={`w-80 lg:w-96 px-6 py-3 text-center border rounded-full ${
                        selectedOptions.includes('caldaie_domestiche') && availableOptions.caldaie_domestiche
                          ? 'bg-[#556423] text-white'
                          : availableOptions.caldaie_domestiche
                          ? 'bg-white'
                          : 'bg-gray-300 cursor-not-allowed'
                      }`}
                      disabled={!availableOptions.caldaie_domestiche}
                    >
                         {t('produzione.caldaie_domestiche')}
                    </button>
                  </div>

                  <div className="relative">
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-html={!availableOptions.prod_pellet ? t('produzione.tooltip') : ""}
                      onClick={() => handleOptionSelect('prod_pellet')}
                      className={`w-80 lg:w-96 px-6 py-3 text-center border rounded-full ${
                        selectedOptions.includes('prod_pellet') && availableOptions.prod_pellet
                          ? 'bg-[#556423] text-white'
                          : availableOptions.prod_pellet
                          ? 'bg-white'
                          : 'bg-gray-300 cursor-not-allowed'
                      }`}
                      disabled={!availableOptions.prod_pellet}
                    >
                         {t('produzione.prod_pellet')}
                    </button>
                  </div>

                  <div className="relative">
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-html={!availableOptions.riduzione_volume ? t('produzione.tooltip') : ""}
                      onClick={() => handleOptionSelect('riduzione_volume')}
                      className={`w-80 lg:w-96 px-6 py-3 text-center border rounded-full ${
                        selectedOptions.includes('riduzione_volume') && availableOptions.riduzione_volume
                          ? 'bg-[#556423] text-white'
                          : availableOptions.riduzione_volume
                          ? 'bg-white'
                          : 'bg-gray-300 cursor-not-allowed'
                      }`}
                      disabled={!availableOptions.riduzione_volume}
                    >
                      {t('produzione.riduzione_volume')}
                    </button>
                  </div>


                  <Tooltip id="tooltip" />
                </div>


                </div>
              </div>

              <button
                onClick={handleSubmit}
                style={{ borderWidth: '3px' }}
                className="border-[#556423] text-[#556423] text-xl lg:text-3xl uppercase font-bold py-4 w-60 lg:w-3/4 rounded-full mt-0 lg:mt-20 flex items-center justify-center"
              >
                {t('produzione.cta')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="ml-2 w-5 h-5 lg:w-8 lg:h-8"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </main>

            <footer className="text-center py-6 bg-[#556423] text-white">
              <button onClick={() => handleRedirectPezzolato()}>
                <p className="inline-flex items-center">
                  {t('produzione.footer')}
                  <span className="font-bold ml-2">
                    <img
                      src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                      alt="Pezzolato Logo"
                      className="w-32"
                    />
                  </span>
                </p>
              </button>
            </footer>
          </div>


      <Toast show={show} setShow={setShow} titolo={titolo} messaggio={messaggio} />
    </>
  );
}

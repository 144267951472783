import React, { useState, useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next'; // Importa l'hook di traduzione
import { languages } from '../locales/languages'; // Importa l'array delle lingue
import '../i18n'; // Importa la configurazione i18n
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

//Richiamo il Context per il SetProdotti e Scelte
import { ProductsContext } from '../context/ProductsContext';
import { SelectionsContext } from '../context/SelectionsContext';




export default function HomePage() {
  const { t, i18n } = useTranslation(); // Hook di traduzione
  const navigate = useNavigate(); // Inizializza l'hook useNavigate
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { setProducts } = useContext(ProductsContext);
  const { setSelections } = useContext(SelectionsContext);


  // Stato per la lingua selezionata
  const [selectedLanguage, setSelectedLanguage] = useState(languages.find(lang => lang.code === i18n.language));

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang.code); // Cambia la lingua
    setSelectedLanguage(lang); // Imposta la lingua selezionata
    setIsDropdownOpen(false); // Chiudi il dropdown dopo la selezione
  };

  const handleRedirectPezzolato = () => {
    window.location.href = `${process.env.REACT_APP_SITO_PEZZOLATO}`;
  };

  const handleRedirectPezzolatocONF = () => {
    window.location.href = `${process.env.REACT_APP_SITO_CONFIGURATORE}`;
  };

  const handleButtonNextAzionamento = () => {
    navigate('/azionamento'); // Naviga verso la pagina Azionamento
  };

  const currentYear = new Date().getFullYear(); // Aggiunta variabile currentYear
     
     //Svuoto lo State delle Scelte e dei prodotto 
     useEffect(() => {
      setProducts([]); // Clear products in context
      setSelections({ azionamento: [], produttivita: [], alimentazione: [], tipologia: [], tipo_produzione: [] }); // Clear selections in context
  }, []);
     
    // Aggiornamento dinamico del titolo e dei meta tag quando cambia la lingua
    useEffect(() => {
      document.title = `© ${t('home.head_title')} | Pezzolato Spa`; // Cambia il titolo
    }, [i18n.language, t]); // Aggiorna quando cambia la lingua o la funzione `t`

  return (
    <>
    <Helmet>
    <meta name="description" content={t('home.meta_descr')} /> {/* Cambia i meta tag */}
    </Helmet>

      <div className="min-h-screen bg-gray-100">
        <section className="flex flex-col lg:flex-row items-stretch w-full min-h-screen relative">
          
          {/* Left Column - Logo and Text Section */}
          <div className="lg:w-1/2 flex flex-col justify-between bk-colu-sx px-8 md:px-32 py-12 space-y-3">
            {/* Logo and Language Selector */}
            <div className="flex justify-between items-center lg:flex-row flex-col">
              {/* Logo */}
              <button onClick={() => handleRedirectPezzolatocONF()}>
                <img
                  src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                  alt="Pezzolato Logo"
                  className="w-44 lg:w-auto"
                />
              </button>

              {/* Language Selector */}
              <div className="lg:hidden my-4 relative">
                <button onClick={toggleDropdown} className="flex items-center space-x-2">
                  {/* Mostra la bandiera e il nome della lingua selezionata */}
                  {selectedLanguage.flag}
                  <span>{selectedLanguage.name}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {isDropdownOpen && (
                  <div className="absolute left-0 right-0 mt-2 w-32 p-1 bg-white border border-gray-200 rounded shadow-lg z-20 text-center">
                    <ul className="text-black">
                      {languages.map(lang => (
                        <li 
                          key={lang.code} 
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer w-60 flex items-center space-x-2"
                          onClick={() => handleLanguageChange(lang)}
                        >
                          {lang.flag}
                          <span>{lang.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* Text Section */}
            <div className="flex flex-col justify-center h-full text-center lg:text-left">
              <h1 className="text-3xl mb:text-4xl lg:text-5xl poppins-semibold text-color-s-green lg:leading-[1.1]">
                <Trans i18nKey="home.header"></Trans>
              </h1>
              <p className="text-lg mb:text-2xl lg:text-3xl text-color-s-green poppins-light lg:leading-[1.29] mt-6">
                {t('home.description')}
              </p>
              <button 
              onClick={() => handleButtonNextAzionamento()}
              className="mt-10 lg:mt-20 bg-[#556423] text-white poppins-bold py-4 px-10 rounded-full inline-flex items-center text-xl lg:text-3xl uppercase tracking-wider transition duration-300 ease-in-out hover:bg-[#6a7a29] mx-auto lg:mx-0 w-auto lg:self-start">
                {t('home.cta')}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="ml-5 w-5 h-5 lg:w-8 lg:h-8">
                  <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>

          {/* Right Column - Language Selector and Image Section */}
          <div className="lg:w-1/2 relative flex flex-col">
            <div className="absolute top-20 right-24 hidden lg:block">
              <button onClick={toggleDropdown} className="flex items-center space-x-2">
                {/* Mostra la bandiera e il nome della lingua selezionata */}
                {selectedLanguage.flag}
                <span className="text-white poppins-semibold" style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                  {selectedLanguage.name}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                    color="white"
                  />
                </svg>
              </button>

              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded shadow-lg z-20">
                  <ul className="text-black text-sm poppins-semibold">
                    {languages.map(lang => (
                      <li 
                        key={lang.code} 
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                        onClick={() => handleLanguageChange(lang)}
                      >
                        {lang.flag}
                        <span>{lang.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <img
              src={`${process.env.REACT_APP_FILESERVER}/home_CIPPATORI.png`}
              alt="Cippatore Image"
              className="w-full h-full object-cover"
            />
          </div>
        </section>

        {/* Solutions Section (under the two columns) */}
        <section className="text-center lg:text-center py-12 home-section">
          <h2 className="text-3xl mb:text-4xl lg:text-5xl poppins-light text-color-s-green text-center mt-2 lg:mt-10">
            {t('home.solutions_title')}
          </h2>
          <p className="mt-4 text-lg text-color-s-green mx-5 lg:mx-20 poppins-extralight lg:text-3xl lg:leading-[1.29] mt-6 lg:mt-14">
            <Trans i18nKey="home.solutions_description"></Trans>
          </p>
          <p className="mt-4 text-lg text-color-s-green mx-5 lg:mx-20 poppins-extralight lg:text-3xl lg:leading-[1.29] mt-6 lg:mt-14">
            <Trans i18nKey="home.solutions_description_2"></Trans>
          </p>
          <p className="mt-4 text-lg text-color-s-green mx-5 lg:mx-20 poppins-extralight lg:text-3xl lg:leading-[1.29] mt-6 lg:my-10 underline">
            {t('home.solutions_cta')}
          </p>
        </section>

        {/* Footer */}
        <footer className="text-center py-6 bk-color-green text-white poppins-regular">
          <button onClick={() => handleRedirectPezzolato()}>
            <p className="inline-flex items-center">
              {t('home.footer')}
              <span className="font-bold ml-2">
                <img
                  src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                  alt="Pezzolato Logo"
                  className="w-32"
                />
              </span>
            </p>
          </button>
        </footer>
      </div>
    </>
  );
}

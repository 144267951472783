import axios from 'axios';

const getAuthToken = async () => {
    try {  
      const config = {
        method: 'post', // Cambia a POST se necessario
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API}/tokens/fetch_one`, // Endpoint per il token
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
            'username': process.env.REACT_APP_USER_GET_TOKEN,
            'password': process.env.REACT_APP_PWD_GET_TOKEN
          }
      };
  
      const response = await axios.request(config);
      return response.data.token; 
    } catch (error) {
      console.error('Error fetching auth token:', error);
      throw error; // Puoi decidere come gestire l'errore
    }
  };

  export default getAuthToken;

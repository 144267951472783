import React, { createContext, useState } from 'react';

// Crea il Context
export const ProductsContext = createContext();

// Provider per fornire il contesto
export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);

  return (
    <ProductsContext.Provider value={{ products, setProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};
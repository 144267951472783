import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles.css';
import { BrowserRouter } from 'react-router-dom'; // Importa BrowserRouter
import Routes from './routes/Routes'; // Importa il file di routing
import reportWebVitals from './reportWebVitals';

//Importo Context Dei Prodotti 
import { ProductsProvider } from './context/ProductsContext';
//Importo Context delle Scelte
import { SelectionsProvider } from './context/SelectionsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter> {/* Avvolgi con BrowserRouter */}
      <ProductsProvider> {/* Avvolgi con ProductsProvider */}
        <SelectionsProvider> {/* Avvolgi con SelectionsProvider */}
          <Routes />
        </SelectionsProvider>
      </ProductsProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

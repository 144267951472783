import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from '../locales/languages';
import '../i18n';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ProductsContext } from '../context/ProductsContext';
import { SelectionsContext } from '../context/SelectionsContext';
import Toast from '../componets/toast';
import { Tooltip } from 'react-tooltip';

export default function TipologiaPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { products, setProducts } = useContext(ProductsContext); 
  const { selections, setSelections } = useContext(SelectionsContext);
  const { tipologia } = selections;  // Corrected from alimentazione to tipologia
  const [selectedOptions, setSelectedOptions] = useState(tipologia || []);
  const [show, setShow] = useState(false);
  const [titolo, setTitolo] = useState('');
  const [messaggio, setMessaggio] = useState('');
  const [availableOptions, setAvailableOptions] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(languages.find(lang => lang.code === i18n.language));

  useEffect(() => {
    const calculateAvailableOptions = () => {
      const options = {
        tronchi: false,
        intera: false,
        potature: false,
        rifili: false,
        sciaveri: false,
        scarti: false,
      };

      products.forEach(product => {
        Object.keys(options).forEach(option => {
          if (product.tags[option]) {
            options[option] = true;
          }
        });
      });

      setAvailableOptions(options);
    };

    calculateAvailableOptions();
  }, [products]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleBackPage = () => {
    navigate('/azionamento');
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang.code);
    setSelectedLanguage(lang);
    setIsDropdownOpen(false);
  };

  const handleOptionSelect = (option) => {
    if (!availableOptions[option]) return;

    let updatedOptions;
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((o) => o !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedOptions);
    setSelections({ ...selections, tipologia: updatedOptions });
  };

  const handleSubmit = () => {
    if (selectedOptions.length === 0) {
      setTitolo(t('tipologia.titolo_toast'));
      setMessaggio(t('tipologia.messaggio_toast'));
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 2000);
      return;
    }

    try {
      let matchingProductsSet = new Set();
      selectedOptions.forEach(option => {
        products.forEach(product => {
          if (product.tags[option]) {
            matchingProductsSet.add(product);
          }
        });
      });

      const uniqueFilteredProducts = Array.from(matchingProductsSet);
      setProducts(uniqueFilteredProducts);

      navigate('/produzione'); // Change to the appropriate next page.
    } catch (error) {
      console.error('Error filtering products:', error);
    }
  };

  const handleRedirectPezzolato = () => {
    window.location.href = `${process.env.REACT_APP_SITO_PEZZOLATO}`;
  };
  



  return (
    <>
      <Helmet>
        <title>© {t('tipologia.head_title')} | Pezzolato Spa</title>
        <meta name="description" content={t('tipologia.meta_descr')} />
      </Helmet>

         <div className="min-h-screen flex flex-col justify-between bg-white">
              {/* Header */}
              <header className="bg-[#F2F2F2]">
          <div className="flex justify-between items-center w-full py-6 px-4 lg:px-32">
            <button onClick={handleBackToHome}>
              <img
                src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                alt="Pezzolato Logo"
                className="w-44"
              />
            </button>
            {/* Language Selector */}
            <div className="relative">
              <button onClick={toggleDropdown} className="flex items-center space-x-2">
                {selectedLanguage.flag}
                <span>{selectedLanguage.name}</span>
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded shadow-lg z-20">
                  <ul className="text-black text-sm">
                    {languages.map((lang) => (
                      <li
                        key={lang.code}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                        onClick={() => handleLanguageChange(lang)}
                      >
                        {lang.flag}
                        <span>{lang.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          
          {/* Progress bar */}
          <div className="flex justify-between w-full px-0 lg:px-0">
            <div onClick={() => handleBackPage()}  className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2 cursor-pointer">
              <span className="poppins-semibold text-color-s-grey">1</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">2</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">3</span>
            </div>
            <div className="h-2 w-1/5 bk-color-green text-center pt-2">
              <span className="poppins-semibold text-color-s-green">4</span>
            </div>
            <div className="h-2 w-1/5 bg-[#DDDDDD] text-center pt-2">
              <span className="poppins-semibold text-color-s-grey">5</span>
            </div>
          </div>
        </header>

             
            <div className="flex flex-col justify-center items-center text-center my-10 w-full">
            <h1 className="text-4xl lg:text-5xl font-semibold text-color-s-green mx-2 mb-0 lg:mb-2 lg:mt-0">
              {t('tipologia.tipo_tipologia')}
            </h1>
            <p className="text-lg text-gray-600 mt-0 lg:mt-0 mb-0 lg:mb-10">{t('tipologia.opzioni')}</p>

            <div className="flex flex-wrap justify-center items-center gap-7 mt-10">
              {/* Usa handleOptionSelect per gestire la selezione */}
              <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('tronchi')}>
              <div
                style={{ borderWidth: selectedOptions.includes('tronchi') ? '4px' : '1px' }}
                className={`rounded-lg w-60 h-60 flex justify-center items-center ${selectedOptions.includes('tronchi') ? 'border-[#556423]' : 'border-[#707070]'} ${!availableOptions.tronchi ? 'cursor-not-allowed' : ''}`}
                data-tooltip-id="tooltip"
                data-tooltip-html={!availableOptions.tronchi ? t('tipologia.tooltip') : ""}
              >
                <img src={`${process.env.REACT_APP_FILESERVER}/tronchi.png`} alt="Tronchi" className="w-full h-full object-cover rounded-lg" />
              </div>
              <h3 className="text-lg poppins-semibold text-color-box mt-2">{t('tipologia.tronchi')}</h3>
            </div>


            <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('intera')}>
              <div
                style={{ borderWidth: selectedOptions.includes('intera') ? '4px' : '1px' }}
                className={`rounded-lg w-60 h-60 flex justify-center items-center ${selectedOptions.includes('intera') ? 'border-[#556423]' : 'border-[#707070]'} ${!availableOptions.intera ? 'cursor-not-allowed' : ''}`}
                data-tooltip-id="tooltip"
                data-tooltip-html={!availableOptions.tronchi ? t('tipologia.tooltip') : ""}
              >
                <img src={`${process.env.REACT_APP_FILESERVER}/pianta-intera.png`} alt="Pianta Intera" className="w-full h-full object-cover rounded-lg" />
              </div>
              <h3 className="text-lg poppins-semibold text-color-box mt-2">{t('tipologia.intera')}</h3>
            </div>


            <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('potature')}>
              <div
                style={{ borderWidth: selectedOptions.includes('potature') ? '4px' : '1px' }}
                className={`rounded-lg w-60 h-60 flex justify-center items-center ${selectedOptions.includes('potature') ? 'border-[#556423]' : 'border-[#707070]'} ${!availableOptions.potature ? 'cursor-not-allowed' : ''}`}
                data-tooltip-id="tooltip"
                data-tooltip-html={!availableOptions.potature ? t('tipologia.tooltip') : ""}
              >
                <img src={`${process.env.REACT_APP_FILESERVER}/ramaglie-potature.png`} alt="Ramaglie e Potature" className="w-full h-full object-cover rounded-lg" />
              </div>
              <h3 className="text-lg poppins-semibold text-color-box mt-2">{t('tipologia.potature')}</h3>
            </div>


            <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('rifili')}>
              <div
                style={{ borderWidth: selectedOptions.includes('rifili') ? '4px' : '1px' }}
                className={`rounded-lg w-60 h-60 flex justify-center items-center ${selectedOptions.includes('rifili') ? 'border-[#556423]' : 'border-[#707070]'} ${!availableOptions.rifili ? 'cursor-not-allowed' : ''}`}
                data-tooltip-id="tooltip"
                data-tooltip-html={!availableOptions.rifili ? t('tipologia.tooltip') : ""}
              >
                <img src={`${process.env.REACT_APP_FILESERVER}/rifili.png`} alt="Rifili" className="w-full h-full object-cover rounded-lg" />
              </div>
              <h3 className="text-lg poppins-semibold text-color-box mt-2">{t('tipologia.rifili')}</h3>
            </div>

            <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('sciaveri')}>
              <div
                style={{ borderWidth: selectedOptions.includes('sciaveri') ? '4px' : '1px' }}
                className={`rounded-lg w-60 h-60 flex justify-center items-center ${selectedOptions.includes('sciaveri') ? 'border-[#556423]' : 'border-[#707070]'} ${!availableOptions.sciaveri ? 'cursor-not-allowed' : ''}`}
                data-tooltip-id="tooltip"
                data-tooltip-html={!availableOptions.sciaveri ? t('tipologia.tooltip') : ""}
              >
                <img src={`${process.env.REACT_APP_FILESERVER}/sciaveri.png`} alt="Sciaveri" className="w-full h-full object-cover rounded-lg" />
              </div>
              <h3 className="text-lg poppins-semibold text-color-box mt-2">{t('tipologia.sciaveri')}</h3>
            </div>

            <div className="text-center cursor-pointer" onClick={() => handleOptionSelect('scarti')}>
              <div
                style={{ borderWidth: selectedOptions.includes('scarti') ? '4px' : '1px' }}
                className={`rounded-lg w-60 h-60 flex justify-center items-center ${selectedOptions.includes('scarti') ? 'border-[#556423]' : 'border-[#707070]'} ${!availableOptions.scarti ? 'cursor-not-allowed' : ''}`}
                data-tooltip-id="tooltip"
                data-tooltip-html={!availableOptions.scarti ? t('tipologia.tooltip') : ""}
              >
                <img src={`${process.env.REACT_APP_FILESERVER}/scarti.png`} alt="Scarti" className="w-full h-full object-cover rounded-lg" />
              </div>
              <h3 className="text-lg poppins-semibold text-color-box mt-2">{t('tipologia.scarti')}</h3>
            </div>

              <Tooltip id="tooltip" />
            </div>

  

            
            {/* Submit Button */}
            <button
              onClick={handleSubmit}
              style={{ borderWidth: '3px' }}
              className="border-[#556423] text-[#556423] text-xl lg:text-3xl uppercase font-bold py-4 w-60 lg:w-10/12 rounded-full mt-8 lg:mt-20 flex items-center justify-center"
            >
              {t('tipologia.cta')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="ml-2 w-5 h-5 lg:w-8 lg:h-8"
              >
                <path
                  fillRule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            </div>

            <footer className="text-center py-6 bg-[#556423] text-white">
              <button onClick={() => handleRedirectPezzolato()}>
                <p className="inline-flex items-center">
                  {t('tipologia.footer')}
                  <span className="font-bold ml-2">
                    <img
                      src={`${process.env.REACT_APP_FILESERVER}/logo_pezzolato.png`}
                      alt="Pezzolato Logo"
                      className="w-32"
                    />
                  </span>
                </p>
              </button>
            </footer>
          </div>
         

      <Toast show={show} setShow={setShow} titolo={titolo} messaggio={messaggio} />
    </>
  );
}

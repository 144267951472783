import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Importa i componenti delle pagine
import HomePage from '../pages/HomePage';
import Azionamento from '../pages/Azionamento';
import ProduttivitaPage from '../pages/Produttivita';
import AlimentazionePage from '../pages/Alimentazione';
import TipologiaPage from '../pages/Tipologia';
import ProduzionePage from '../pages/Produzione';
import RisultatiPage from '../pages/Risultati';


// Importa altre pagine se necessarie

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} /> {/* Utilizza element */}
      <Route path="/azionamento" element={<Azionamento />} /> {/* Utilizza element */}
      <Route path="/produttivita" element={<ProduttivitaPage />} /> {/* Utilizza element */}
      <Route path="/alimentazione" element={<AlimentazionePage />} /> {/* Utilizza element */}
      <Route path="/tipologia" element={<TipologiaPage />} /> {/* Utilizza element */}
      <Route path="/produzione" element={<ProduzionePage />} /> {/* Utilizza element */}
      <Route path="/risultati" element={<RisultatiPage />} /> {/* Utilizza element */}
      {/* Aggiungi altre route qui */}
    </Routes>
  );
};

export default AppRoutes;
